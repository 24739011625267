html, body 
{
  width: auto!important; 
  overflow-x: clip!important
} 

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
      }

      .backgroundgrey {
        background-color: #a9b4ba;
      }
      .black, .fontcolour {
        color: #000;
      }
      .backgroundblack {
        background-color: #000;
      }
      .white {
        color: white;
      }

      .mainsection {
          display: flex;
          padding-bottom: 20px;
      }

      .section {
        width: 50%;
      }

      .height750 {
        height: 750px;
      }

      .mainsectionimage {
        width: 99%;
      }

      .mainsectionimage.height {
        height: 110%;
        width: 80%;
      }

      .squarecontainer {
        position: relative;
        text-align: center;
        color: white;
      }

      .top-centered {
        position: absolute;
        top: 4px;
        left: 35%;

      }

      .square .top-centered {
        left: 22%;
      }

      .bottom-centered-learn {
          position: absolute;
          bottom: 10px;
          left: 45%;
      }
      .square .bottom-centered-learn {
          left: 30%;
      }

      .bottom-centered-buy {
        position: absolute;
        bottom: -6px;
        left: 35%;
    }

    .imagebkgroundwhite .fontcolour {
      color: #000;
    }

    .imagebkgroundblack .fontcolour {
      color: #fff;
    }

    .background-black-gradient {
      background: linear-gradient(174deg,#1d1d1d,#f4f4f4 86%,#fff);
    }

    .background-teal-gradient {
      background: linear-gradient(144deg,#343c3a 5%,#78807d 70%,#cbd1cf);
    }

    .background-maroon-gradient {
      background: linear-gradient(216deg,#645957 7.62%,#aea09e 45.4%,#edebeb 98.67%);
    }

    .background-orange-gradient {
      background: linear-gradient(224deg,#eee5d3 20%,#fffaf4 81.18%);
    }
    

    .display-none {
      display: none;
    }

      .width100 {
        width:100%;
      }

      .width80 {
        width: 80%;
      }

      .width130 {
        width: 130%;
      }

      .flex {
        display: flex;
      }

      .width50 {
        width: 50%;
      }

      .textleft {
        text-align: left;
      }

      .mini img {
        height:850px;
      }
      .mix-blend-mode {
        mix-blend-mode: darken;
      }

      .opacity {
        opacity: 0;
      }

      .opacity.scrolled {
        opacity: 1;
      }

      .reveal-text {
        font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 30px;
      }

      .darkgrey {
      color: darkslategrey;
      }

     
      /* .section > img {
        animation-name: fade-in;
        animation-timeline: view();
        animation-timing-function: linear;
        animation-range: entry cover;
        animation-fill-mode: both;
      }

      @keyframes fade-in {

        from {transform: scaleY(0);}
        to {transform: scaleY(2);}
        
      } */

      .img-animation-scaleX {
        animation-timeline: view(block 50% 10%); 
        animation-name: grow;
        animation-fill-mode: both;
        animation-duration: 1ms; /* Firefox requires this to apply the animation */
        animation-timing-function: linear;
        animation-range: entry cover;
      }
      
      @keyframes grow {
        from {
          transform: scaleX(.5);
        }
      
        to {
          transform: scaleX(1);
        }
      }

      .img-animation-fadeout {
        animation-timeline: view(); 
        animation-name: fadeout;
        animation-fill-mode: both;
        animation-duration: 1ms; /* Firefox requires this to apply the animation */
        animation-timing-function: linear;
        animation-range: exit;
      }
      
      @keyframes fadeout {
        from {
          opacity: 1;
        }
      
        to {
          opacity: .2;
        }
      }
      
    

      @media screen and (max-width: 960px) {
        .reveal-text-container .reveal-text, .section, .width50 {
          width:100%;
        }
        
        .mainsection, .flex {
          display: block;
        }
        .height750 {
          height: 400px;
        }
        .section {
          display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        }

        .bottom-centered-learn, .square .bottom-centered-learn {
          left: 18%;
        }

      }