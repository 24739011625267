.headline {
    font-size: 57px;
    font-weight: 600;
    letter-spacing: -.005em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    margin: 0px;
    padding-top: 30px;
}

.subheadline {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: .007em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    margin-top: 6px;
}

.white {
    color: white;
}

 .animateHeader {
    animation-name: displayslow;
    animation-duration: 5s;
} 

.animateSubHeader, .reveal-text {
    animation-name: displayslow;
    animation-duration: 10s;
}

@keyframes displayslow {
    from {opacity:0;}
    to {opacity:1;}
  } 
