.grid-container {
    display: grid;
    grid-template-columns: 24.8% 24.8% 24.8% 24.8%;
    gap: 10px;
    background-color: White;
    padding: 10px;
  }
  
  .grid-container > div, .grid-container-product-detail > div {
    background-color: rgb(247, 243, 243);
    text-align: center;
    padding: 10px 0;
    font-size: 30px;
  }

  .marginTop20 {
    margin-top: 20px;
  }

  .grid-container-product-detail {
    display: grid;
    grid-template-columns: 60% 39%;
    gap: 10px;
    background-color: White;
    padding: 10px;
  }

  .width {
    width: 80%;
  }

  .imgProductDetail {
    width: 90%;
  }

  @media screen and (max-width: 960px) {

    .grid-container, .grid-container-product-detail {
      grid-template-columns: none;
    }

    .width {
      width: 100%;
    }
  }